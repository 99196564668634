<template>
  <div class="modal fade" :id="title" data-bs-backdrop="static" data-bs-keyboard="false"
       tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" style="max-width: 1000px">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title fs-5" :id="'static' + title">{{ name }}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <slot>
          </slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default ({
  name: "ModalWindow",
  props: {
    photos: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  }
})
</script>

<style scoped lang="scss">

.modal-body {
  text-align: start;
}
</style>