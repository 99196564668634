<template>
<!--  <img :src="" alt="">-->
  <ModalWindow :title="'staticBackdropPrices'" :name="'Цены на наши услуги'">
    <ul>
      <li>
        <h4>1-я категория сложности (не запущенный) - <strong
            style="color: black">2300р.</strong></h4>
      </li>
      <li>
        <h4>2-я карегория сложности (средняя сложность в том числе при диабете и грибке) -
          <strong style="color: black">2800р.</strong></h4>
      </li>
      <li>
        <h4>3-я категория сложности (запущенные) - <strong style="color: black">3200р.</strong>
        </h4>
      </li>
      <li>
        <h4> Гигиенический аппаратный маникюр - <strong
            style="color: black">500р.-1200р.</strong></h4>
      </li>
    </ul>
    <h2>Что входит в услугу:</h2>
    <ul>
      <li>осмотр и консультация</li>
      <li>Обработка стопы (трещины, мозоли, натоптыши)</li>
      <li>Обработка и подстригание ногтей (грибок, дистрофия, вросший ноготь, подногтевые и
        стержневые мозоли
      </li>
      <li>при необходимости - перевязка, разгрузка</li>
      <li>по завершению - покрытие ногтей маслом и увлажняющий крем для стопы</li>
    </ul>
    <h2>
      Стоимость выезда:
    </h2>
    <ul>
      <li>
        в пределах МКАД - 150р.
      </li>
      <li>
        до 15 км. от МКАД - 350р.
      </li>
      <li>
        15-30 км. от МКАД - 500р.
      </li>
    </ul>
  </ModalWindow>
  <ModalWindow :title="'staticBackdropExamples'" :name="'Примеры наших работ'" >
   <div class="d-flex overflow-x-auto" v-if="examplesIsShowing">
     <div  v-for="example in examples()" :key="example">
       <img class="me-3" :src="example.path" alt="" style="max-height: 300px" >
     </div>
   </div>

  </ModalWindow>
  <ModalWindow :title="'staticBackdropContacts'" :name="'Позвоните нам!'">
    <div class="modal-bg p-3">
      <div class="modal-border p-3">
        <div class="modal-logo d-flex">
          <img :src="require('@/assets/images/logo-life-step.png')" alt="">
          <div class="modal__name">
            <p>
              <span>Твой</span>
              <span>Подолог</span>
            </p>
            <p>Выездной центр подологии</p>
          </div>
        </div>
        <div class="modal-contacts py-3 px-3">
          <div class="modal-contacts__contact-block row justify-content-between">
            <div class="d-flex col-md-5 mb-2">
              <img :src="require('@/assets/images/phone.png')" alt="">
              <div class="d-flex align-items-end">
                <a href="tel:8 977 171 88 86">+7977171-88-86</a>
              </div>
            </div>
            <div class="d-flex col-md-7 align-items-end mb-2">
              <h1 class="px-2 m-0" style="color: #009DA2">WhatsApp</h1>
              <div class="d-flex">
                <a href="https://wa.me/79771718886" target="blank">+7977171-88-86</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalWindow>
  <ModalWindow :title="'staticBackdropFeedback'" :name="'Отзывы наших клиентов'">
    <CarouselComponent :photos="feedback()" :title="'feedback'" v-if="feedbackIsShowing"/>
  </ModalWindow>
  <div>
    <Header @open-examples="openExamplesModal()" @open-feedback="openFeedbackModal()"/>
    <main>
      <div class="container">
        <div class="row">
          <div class="col-3 sidebar_section" v-if="showSidebar">
            <div class="sidebar">
              <div class="sidebar_links-block">
                <ul class="sidebar_links__list">
                  <li class="list-item">
                    <button class="btn btn-primary w-100" type="button" data-bs-toggle="modal"
                            data-bs-target="#staticBackdropPrices" >
                      <i class="bi bi-cash" style="font-size: 25px"></i>
                      Цены
                    </button>
                  </li>
                  <li class="list-item">
                    <button class="btn btn-primary w-100" type="button" data-bs-toggle="modal"
                            data-bs-target="#staticBackdropExamples" @click="examplesIsShowing = true">
                      <i class="bi bi-bandaid"></i>
                      Работы
                    </button>
                  </li>
                  <li class="list-item">
                    <button class="btn btn-primary w-100" type="button" data-bs-toggle="modal"
                            data-bs-target="#staticBackdropContacts">
                      <i class="bi bi-clipboard2-check"></i>
                      Записаться
                    </button>
                  </li>
                  <li class="list-item">
                    <button class="btn btn-primary w-100" type="button" data-bs-toggle="modal"
                            data-bs-target="#staticBackdropFeedback" @click="feedbackIsShowing = true">
                      <i class="bi bi-chat-left-heart"></i>
                      Отзывы
                    </button>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div :class="classMain" >
            <h1>
              Позвольте нам позаботиться о вас!
            </h1>
            <div class="company-info_block">
              <InfoCard class="info-card d-flex" :background="require('@/assets/images/sterilization-mask.png')">
                <ol class="info-card_list col">
                  <li>За время работы большое количество довольных клиентов.</li>
                  <li>Мы используем профессиональное оборудование и инструменты.</li>
                  <li>Четырёхфазная стерилизация инструментов</li>
                  <li>Мастера с профильным подологическим образованием.</li>
                </ol>
              </InfoCard>
              <InfoCard class="info-card d-flex" :background="require('@/assets/images/working.png')">
                <p class="col">
                  Оказываем <strong>услуги подологии и медицинского
                  педикюра</strong> с выездом <strong>на дом, в медицинские учреждения, пансионаты и в дома
                  кратковременного и постоянного
                  проживания</strong> пожилых людей. Также оказываем услуги <strong>маломобильным группам населения,
                  инвалидам и пожилым.</strong>
                  Выездной центр основан в 2017 году. Мы - это профессиональный, доступный и
                  безопасный сервис. Наша цель - <strong>сохранение здоровья и повышение качества вашей
                  жизни.</strong> <i>Мы искренне вовлечены в наше дело.</i></p>
              </InfoCard>
              <InfoCard class="info-card" :background="require('@/assets/images/old-client.png')">
                <p class="col" style="font-size: 1rem; color: #0a53be; font-weight: bold">
                  Ветеранам ВОВ скидка 10%! Для всех клиентов при повторных обращениях применяется скидочная система в
                  5%, 7% и 10%!
                </p>
              </InfoCard>
            </div>
            <button class="btn default-btn w-100 p-2" type="button" data-bs-toggle="modal"
                    data-bs-target="#staticBackdropExamples" @click="examplesIsShowing = true">Посмотреть наши работы</button>
            <h2>Наши специалисты</h2>
            <div class="masters_section" v-for="master in masters" :key="master">
              <MasterInfo :photo="master.photo" :info="master.info"></MasterInfo>
            </div>
            <h2>Работаем в Москве и Московской области (до 20 км от МКАД)</h2>
            <img class="work-area" :src="require('@/assets/images/map.png')" alt=""
                 style="border: 2px solid #009da2; border-radius: 10px; margin: 30px 0">
            <h2>Сертификаты</h2>
            <div class="documents_block">
              <CarouselComponent :photos="photosArr" :title="'certificates'"></CarouselComponent>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import InfoCard from "@/components/InfoCard.vue";
import MasterInfo from "@/components/MasterInfo.vue";
import Masters from "@/helpers/mastersInfo";
import CarouselComponent from "@/components/CarouselComponent.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import {examples, feedback} from '@/helpers/photosExamples.js'


export default {
  name: "LandingView",
  components: {Footer, Header, InfoCard, MasterInfo, CarouselComponent, ModalWindow},
  data() {
    return {
      width: window.innerWidth,
      showSidebar: true,
      classMain: 'col-9',
      masters: Masters,
      examplesIsShowing: false,
      feedbackIsShowing: false,
      photosArr: [
        {
          path: require('@/assets/images/certificate1.png')
        },
        {
          path: require('@/assets/images/certificate2.png')
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Вызываем один раз для первоначальной установки
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    examples() {
      return examples
    },
    feedback() {
      return feedback
    },
    openExamplesModal () {
      this.examplesIsShowing = true;
    },
    openFeedbackModal () {
      this.feedbackIsShowing = true;
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    handleResize() {
      this.updateWidth();

      if (this.width < 1024) {
        this.showSidebar = false;
        this.classMain = 'col-12';
      } else {
        this.showSidebar = true;
        this.classMain = 'col-9';
      }
    },
  },

}
</script>

<style scoped lang="scss">
h1 {
  color: #DA8A67;
  margin-bottom: 50px;
}

h2, h3, h4 {
  color: #DA8A67;
  margin: 30px 0;
}

.sidebar {
  position: sticky;
  top: 50px;

  .sidebar_links__list {
    list-style: none;
    padding-left: unset;

    .list-item {
      color: black;
      font-weight: bold;
      margin: 5px;
      padding: 10px;

      .btn-primary {
        background-color: unset;
        border: unset;
        color: #DA8A67;
        text-align: start;
        font-weight: bold;
        font-size: 18px;
      }

      i {
        font-size: 20px;
        margin-right: 10px;
        color: #009da2;
      }
    }
  }
}

.card-inner {
  p {
    font-size: 1rem;
  }
}

.info-card {
  margin-bottom: 40px;
}

.info-card_list {
  li {
    margin: 10px 0 0;
    font-weight: bold;
    font-size: 1rem;
  }
}

.default-btn {
  width: 100%;
  margin: 30px 0;
  padding: 10px 5px;
  background-color: #009da2;
  border: 2px solid #009da2;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: underline;
}

.masters_section {
  margin-top: 40px;
}

@media screen and (max-width: 1023px)  and (min-width: 320px){
  .info-card_list {
    padding: 0 30px;

    li {
      font-size: 0.9em;
    }
  }

  .card-inner {

    ol{
      margin-top: 15px;
    }

    p {
      margin-top: 15px;
      font-size: 14px!important;
      min-width: 200px;
    }
  }

  .master-info_block {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (min-width: 1023px){
  .work-area {
    max-width: 700px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 758px){
  .work-area {
    max-width: 540px;
  }
}

@media screen and (max-width: 757px) and (min-width: 501px){
  .work-area {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) and (min-width: 300px){
  .work-area {
    max-width: 300px;
    //min-width: 800px;
  }
}

</style>