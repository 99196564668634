<template>
  <footer class="footer mt-5">
    <div class="footer-container">
      <div class="container">
        <div class="d-flex justify-content-between">
          <ul class="p-4" style="list-style: none;">
            <li class="d-flex align-items-center mb-3">
              <img class="pe-2" :src="require('@/assets/images/phone-white.png')" style="height: 50px" alt="">
              <a class="text-white fs-4" href="tel:+8 977 171 88 86">+7977171-88-86</a>
            </li>
            <li class="d-flex align-items-center pt-3"
            >
              <h3 class="px-2 m-0 text-white" style="color: #009DA2">WhatsApp</h3>
              <a class="text-white fs-4" href="https://wa.me/79771718886">+7977171-88-86</a>
            </li>
          </ul>
          <div class="d-grid justify-content-center">
            <p>
              <span class="text-white">Ежедневно с 9:00 до 20:00</span>
            </p>
            <p>
              <span class="text-white">©Твой подолог 2024</span>
            </p>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer-container {
  background-color: #009DA2;
  padding: 50px 0;
}
</style>