
<template>
  <div class="master-info_block">
    <div class="master-info_block__photo">
      <img :src="photo" alt="">
    </div>
    <div class="master-info_block__description">
      <p>
        {{info}}
      </p>
    </div>
  </div>
</template>

<script>

export default ({
  name: "MasterInfo",
  props: {
    photo: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    }
  },
})
</script>

<style scoped lang="scss">
  .master-info_block {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    margin: 30px 0 20px;

      .master-info_block__photo {

        img {
          max-width: 300px;
          max-height: 300px;
        }
      }
    .master-info_block__description {
      display: flex;
      align-items: center;
      font-size: 1em;
    }

  }

  @media screen and (max-width: 1023px) and (min-width: 320px){
    .master-info_block {
      .master-info_block__description {
        margin-top: 15px;
        max-width: 350px;
      }
    }
  }
</style>