<template>
  <div>
    <div :id="title" class="carousel slide m-auto" style="max-width: 600px">
      <!-- Carousel indicators -->
      <div class="carousel-indicators">
        <button
            v-for="(photo, index) in photos"
            :key="index"
            type="button"
            :data-bs-target="'#' + title"
            :data-bs-slide-to="index"
            :class="{ active: index === 0 }"
            :aria-label="`Slide ${index + 1}`"
            :aria-current="index === 0 ? 'true' : 'false'"
        ></button>
      </div>

      <!-- Carousel inner items -->
      <div class="carousel-inner">
        <div
            v-for="(photo, index) in photos"
            :key="index"
            :class="['carousel-item', { active: index === 0 }]"
        >
          <img :src="photo.path" class="d-block w-100" alt="...">
        </div>
      </div>

      <!-- Carousel controls -->
      <button class="carousel-control-prev" type="button" :data-bs-target="'#' + title"
              data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" :data-bs-target="'#' + title"
              data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>

export default ({
  name: "CarouselComponent",
  props: {
    photos: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.carousel {
  max-width: 400px;
  height: 400px;

  .carousel-inner {
    height: 400px;
  }

  .carousel-item {
    height: 100%;
    align-items: center;
    border-radius: 10px;

    &.active,
    &.carousel-item-start,
    &.carousel-item-prev {
      display: flex;
      align-items: center;
      background-color: black;
    }

    &.carousel-item-prev {
      height: 100%;
      border-radius: 10px;
    }

  }
}
</style>