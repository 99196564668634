
function importAll(directory, basePath) {
    return directory.keys().map((key) => ({
        path: require(`@/assets/images/${basePath}/${key.replace('./', '')}`)  // Формируем путь в требуемом формате
    }));
}

// Импортируем изображения из директорий с требуемым путем
export const examples = importAll(require.context('@/assets/images/examples', false, /\.(png|jpe?g|svg)$/), 'examples');
export const feedback = importAll(require.context('@/assets/images/feedback', false, /\.(png|jpe?g|svg)$/), 'feedback');

// Экспортируем оба массива
export default { examples, feedback };
