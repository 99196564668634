<template>
  <div class="header container-fluid">
    <div class="container d-flex justify-content-between">
      <div class="header_logo-block">
        <div class="d-flex align-items-center">
          <img :src="require('@/assets/images/logo-life-step.png')" alt="">
          <div class="logo-name">
            <span class="fs-1 fw-bold fst-italic" style="color: #DA8A67"> Твой</span>
            <span class="fs-1 fw-bold fst-italic" style="color: #009DA2">Подолог</span>
            <p style="font-style: italic; font-weight: bold; color: #009da2">Выездной центр. Работаем с 2017 года.</p>
          </div>

        </div>
      </div>
      <div class="header_contacts">
        <div class="me-2" style="color: #8c8c8c">
          <div>Режим работы:</div>
          <div>Ежедневно 09:00-20:00</div>
        </div>
        <a href="tel:+7 977 171 88 86">Телефон: +7 977 171 88 86</a>
        <a href="https://wa.me/79771718886" target="_blank"><img class="contact-icon me-1" src="../assets/images/phone.png" alt="" />
          <span style="font-weight: bold; color:#009da2 ">WhatsApp</span>
        </a>
      </div>
      <div class="mobile-menu">
        <button class="btn" type="button" data-bs-toggle="collapse" data-bs-target="#mobileActionButtons" aria-expanded="false" aria-controls="mobileActionButtons">
          <i class="bi bi-list"></i>
        </button>
      </div>
    </div>
    <div class="collapse" id="mobileActionButtons">
      <div class="card-body d-flex flex-wrap justify-content-between">
        <button class="btn" type="button" data-bs-toggle="modal"
                data-bs-target="#staticBackdropPrices">
          <i class="bi bi-cash" style="font-size: 25px"></i>
          Цены
        </button>
        <button class="btn" type="button" data-bs-toggle="modal"
                data-bs-target="#staticBackdropExamples" @click="openExamples()">
          <i class="bi bi-bandaid"></i>
          Работы
        </button>
        <button class="btn" type="button" data-bs-toggle="modal"
                data-bs-target="#staticBackdropContacts">
          <i class="bi bi-clipboard2-check"></i>
          Записаться
        </button>
        <button class="btn" type="button" data-bs-toggle="modal"
                data-bs-target="#staticBackdropFeedback" @click="openFeedback()">
          <i class="bi bi-chat-left-heart"></i>
          Отзывы
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  emits:[
      'open-examples',
      'open-feedback'
  ],
  methods: {
    openExamples () {
      this.$emit('open-examples')
    },
    openFeedback() {
      this.$emit('open-feedback')
    }
  }
}
</script>

<style scoped lang="scss">
  .header_contacts {
    align-items: center;
    display: flex;
    gap: 10px;

    a {
      color: #8c8c8c;
      text-decoration: unset;

      .contact-icon {
        width: 30px;
        height: 30px;
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  .card-body {
    display: none;

    button {
      display: none;

      i {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1023px)  and (min-width: 320px){

    .header {
      padding: unset;

      .header_logo-block {

        .fst-italic {
          font-size: 1.5em!important;
        }

        img {
          width: 60px;
        }

        .logo-name {
          p {
            font-size: 10px;
          }
        }
      }

      .header_contacts {
        display: none;
      }

      .mobile-menu {
        display: flex;
        align-items: center;

        .btn {
          i {
            font-size: 50px;
            font-weight: bold;
            color: #DA8A67;
          }
        }

      }

      #mobileActionButtons {
        padding: 0 10px;

        .card-body {
          margin-bottom: 40px;
          background-color: rgb(179 179 179 / 17%);
          border-radius: 10px;
          display: block;

          button {
            color: #009da2;
            font-size: 1.2em;
            margin: 15px 5px;
            display: block;

            i {
              color: #DA8A62;
              font-size: 1.2em;
              display: block;
            }
          }
      }


      }
    }
  }
</style>