<script>
export default ({
  name: "InfoCard",
  props: {
    background: String
  },
  data () {
    return {
    }
  }
})
</script>

<template>
  <div class="card-section">
    <div class="card-inner row" >
      <img :src="background" alt="">
        <slot>
        </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .card-section {
    border-radius: 10px;

    .card-inner {
      align-items: center;


      img {
        width: 380px;
        height: 200px;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
      }
    }
  }
</style>