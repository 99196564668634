const Masters = [
    {
        name: 'Желтухина Ангелина Олеговна',
        info: 'Желтухина Ангелина Олеговна — подолог, мастер высшей категории с многолетним опытом работы в ногтевом сервисе. Начала профессиональный путь в 2019 году, окончив курс по аппаратному педикюру и маникюру, а в 2024 году с отличием завершила обучение в PodoInstitut в Москве. Специализируется на обработке ногтей с грибковыми поражениями, утолщении ногтевой пластины, деформациях, отслоениях, а также диабетической стопе и гиперкератозе. Имеет значительный опыт работы с пожилыми людьми и маломобильными группами населения, Профессионально занимается любимым делом уже более 5-и лет.',
        photo: require('@/assets/images/master-angelina.png')
    },
    {
        name: 'Чичерова Снежана Николаевна',
        info: 'Чичерова Снежана Николаевна — мастер высшей категории и специалист широкого профиля в области подологии, аппаратного педикюра и маникюра. В 2022 году окончила PodoInstitut в Москве по курсу «Подология». Специализируется на коррекции вросших ногтей, диабетической стопе, грибке, микозах, трещинах на пятках, мозолях и онихогрифозе, выполняя медицинский педикюр любой сложности. Имеет более 10 лет опыта и постоянно повышает квалификацию у российских и немецких специалистов.',
        photo: require('@/assets/images/master-snejana.png')
    }
];

export default Masters;